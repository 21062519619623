<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventLocal.title }}
          </h5>
        </div>
          <b-form
            class="p-2">
              <b-form-group
                label="股票名"
                label-for="event-title">
                <b-form-input
                  id="event-title"
                  v-model="eventLocal.title"
                  trim
                  disabled/>
              </b-form-group>

              <b-form-group
                label="申购开始日期"
                label-for="start-date">
                <flat-pickr
                  v-model="eventLocal.start"
                  class="form-control"
                  disabled
                  :config="{ enableTime: true, dateFormat: 'Y-m-d'}"/>
              </b-form-group>

              <b-form-group
                label="申购结束日期"
                label-for="end-date">
                <flat-pickr
                  v-model="eventLocal.end"
                  class="form-control"
                  disabled
                  :config="{ enableTime: true, dateFormat: 'Y-m-d'}"/>
              </b-form-group>

            <b-form-group
                label="股票代码"
                label-for="event-title">
              <b-form-input
                  id="event-title"
                  v-model="eventLocal.extendedProps.symbol"
                  trim
                  disabled/>
            </b-form-group>

            <b-form-group
                label="申购代码"
                label-for="event-title">
              <b-form-input
                  id="event-title"
                  v-model="eventLocal.extendedProps.code"
                  trim
                  disabled/>
            </b-form-group>

            <b-form-group
                label="预计发行量"
                label-for="event-title">
              <b-form-input
                  id="event-title"
                  v-model="eventLocal.extendedProps.code"
                  trim
                  disabled/>
            </b-form-group>

            <b-form-group
                label="发行价"
                label-for="event-title">
              <b-form-input
                  id="event-title"
                  v-model="eventLocal.extendedProps.price"
                  trim
                  disabled/>
            </b-form-group>

            <b-form-group
                label="申购上限"
                label-for="event-title">
              <b-form-input
                  id="event-title"
                  v-model="eventLocal.extendedProps.max"
                  trim
                  disabled/>
            </b-form-group>

            <b-form-group
                label="券商"
                label-for="event-title">
              <b-form-input
                  id="event-title"
                  v-model="eventLocal.extendedProps.brokerage"
                  trim
                  disabled/>
            </b-form-group>
          </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { required, email, url } from '@validations'
import { toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    const {
      eventLocal,
    } = useCalendarEventHandler(toRefs(props), emit)
    return {
      eventLocal,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
