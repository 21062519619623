<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"/>
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @update-event="updateEvent"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import calendarStoreModule from './calendarStoreModule'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    FullCalendar,
    CalendarEventHandler,
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })
    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      updateEvent,
      fetchEvents,
      calendarOptions,
      isEventHandlerSidebarActive,
    } = useCalendar()
    fetchEvents()
    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      updateEvent,
      calendarOptions,
      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "src/@core/scss/vue/apps/calendar.scss";
</style>
