import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
export default function userCalendar() {
  const toast = useToast()
  const refCalendar = ref(null)
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })
  const calendarsColor = {
    Stock: 'primary',
  }
  const blankEvent = {
    title: '',
    start: '',
    end: '',
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      guests: [],
      location: '',
      description: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      extendedProps: { calendar, guests, location, description, name, symbol, code, volume, price, brokerage, max},
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      extendedProps: {calendar, guests, location, description, name, symbol, code, volume, price, brokerage, max,},
      allDay,
    }
  }
  const updateEvent = eventData => {
    store.dispatch('calendar/updateEvent', { event: eventData }).then(response => {
      const updatedEvent = response.data.event

      const propsToUpdate = ['id', 'title', 'url']
      const extendedPropsToUpdate = ['calendar', 'guests', 'location', 'description']

      updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
  }
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

  watch(selectedCalendars, () => {
    refetchEvents()
  })
  const fetchEvents = (info, successCallback) => {
    if (!info) return
    store
      .dispatch('calendar/fetchEvents', {
        calendars: selectedCalendars.value,
      })
      .then(response => {
        console.log(response.data.data)
        successCallback(response.data.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendar events',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: '',
    },
    events: fetchEvents,
    editable: true,
    eventResizableFromStart: true,
    dragScroll: true,
    dayMaxEvents: 5,
    navLinks: true,
    eventClassNames({ event: calendarEvent }) {
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]
      return [
        `bg-light-${colorName}`,
      ]
    },

    eventClick({ event: clickedEvent }) {
      event.value = grabEventDataFromEventApi(clickedEvent)
      isEventHandlerSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        text: 'sidebar',
        click() {
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })
  const isEventHandlerSidebarActive = ref(false)
  const isCalendarOverlaySidebarActive = ref(false)
  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    updateEvent,
    fetchEvents,
    isEventHandlerSidebarActive,
  }
}
