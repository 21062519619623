import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'

export default function useCalendarEventHandler(props, emit) {
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
  }
  watch(props.event, () => {
    resetEventLocal()
  })
  const calendarOptions = computed(() => store.state.calendar.calendarOptions)
  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))

    if (props.event.value.id) emit('update-event', eventData.value)
    else emit('add-event', eventData.value)
    emit('update:is-event-handler-sidebar-active', false)
  }
  return {
    eventLocal,
    resetEventLocal,
    calendarOptions,
    onSubmit,
  }
}
